/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import heroBg from './assets/dot-pattern.svg'
import growth from './assets/growth.svg'
import holographic from "@elegantstack/gatsby-theme-flexiblocks/src/homepage/fintech/assets/holographic.jpg";

export default {
    heroContainer: {
        bg: `#F6F7FA`,
        pt: [5, 6],
    },
    testimonialsContainer: {
        position: `relative`,
        pt: 3,
        pb: 5,
        '::before': {
            position: `absolute`,
            content: `" "`,
            width: `full`,
            height: `full`,
            top: 0,
            right: 0,
            zIndex: -1,
            borderRadius: `xl`,
            background: `linear-gradient(
        180deg,
        #f7f9fe 0%,
        #f4f4f8 100%
      )`
        }
    },
    featureOneContainer: {
        position: `relative`,
        py: [3],
        '::before, ::after': {
            position: `absolute`,
            content: `" "`,
            size: `70vw`,
            maxWidth: `500px`,
            maxHeight: `500px`,
            top: `50%`,
            left: `0%`,
            transform: [`translate(15vw, -185%)`, `translate(20%, -85%)`],
            zIndex: -2,
            borderRadius: `full`
        },
        '::after': {
            background: `linear-gradient(
          180deg,
          rgba(255,255,255,0.2) 0%,
          rgba(255,255,255,0.9) 100%
        )`
        },
        '::before': {
            background: `url(${holographic}) no-repeat center center`,
            backgroundSize: `cover`
        }
    },
    buildBrandContainer: {
        position: `relative`,
        '::before': {
            content: `" "`,
            size: `full`,
            position: `absolute`,
            right: 0,
            top: `13%`,
            zIndex: -1,
            background: `url(${heroBg}) no-repeat center center`,
            opacity: 0.3,
            backgroundSize: `40%`
        }
    },
    getStartedContainer: {
        position: `relative`,
        '::before': {
            content: `" "`,
            size: `full`,
            position: `absolute`,
            top: [`25%`, 0],
            right: 6,
            zIndex: -1,
            background: `url(${growth}) no-repeat right 0`,
            backgroundSize: `55%`
        }
    },
    ctaContainer: {
        'img, .block-overlay': {
            borderRadius: `xl`,
            zIndex: -3,
            bg: `white`,
            background: t => `linear-gradient(
        145deg,
        ${t.colors.alpha} 0%,
        ${t.colors.beta} 100%
      )`
        }
    }
}
